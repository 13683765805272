<template>
  <div id="chatroom">
    <!-- <div style="widht:100vw; height:5vh;position:fiex; top:0">
      <img src="" alt="">
    </div> -->
    <van-nav-bar
      @click-left="back"
    >
      <template slot="left">
        <van-icon color="black" name="arrow-left" />
      </template>
    </van-nav-bar>
    <div class="qrcodecard">
      <h5>请微信联系客服</h5>
      <img id="qrcodeimg" src="https://static.ainvestcn.com/xiaoqi_20191112150637.jpg" style="width:61.8vw;" alt="" srcset="">
      <h5 class="title">客服工作时间:
        周一到周五
        <br> 9:00 ~ 18:00</h5>
    </div>
  </div>
</template>
<script>
import { NavBar, Icon } from 'vant'

export default {
	components: {
		[NavBar.name]: NavBar,
		[Icon.name]: Icon
	},
	data() {
		return {

		}
	},
	methods: {
		back() {
			console.log(this.$router)
			this.$router.go(-1)
		}
	}
}
</script>
<style lang="less" scoped>
.top {
  // bottom: 4rem;
  top: 0;
  // height:3.5rem;
  height: 7.5vh;
  position: fixed;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.99);
  .logo{

    width:44vw;
    // height: 5vh;
    margin-left:20.5vw;
    margin-top:1.2vh;
  }
  .returns{
    width: 4vh;
    // height: 5vh;
    margin-bottom: 0.4vh;
  }
}
.return{
  width:100vw;
  height:3vh;
  background:white;
  position: fixed;
  top: 0;
  img{
    width: 5vw;
    height: 2vh;
    margin-top: 0.5vh;
  }
}
#chatroom{
  width:100vw;
  height:100vh;
  background-color:rgba(0, 0, 0, 0.618);
}
.qrcodecard{
  display: flex;
  display: -webkit-flex;
  // border: 1px solid #000000;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h5{
    background-color:white;
    width:61.8vw;
    // height: 4vh;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    text-align: center;
    padding: 1vh 0;
    line-height: 3vh;
  }
  #qrcodeimg{
    border:1px solid #000000;
    box-shadow: 0 0 0vw #000000;
  }
  .btn{
    margin-top:10vh;
  }
}
</style>
